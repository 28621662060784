import React, {useState} from 'react';
import Modal from '../../../../Shared/Components/Modal';
import Alert from '../../../../Shared/Components/Alert';
import {Button, Spin} from 'antd';
import {Trans} from 'react-i18next';

interface SensorTemplateExportProps {
	isVisible: boolean;
	configName: string;
	exportSensorConfigAsJson?: (configName: string) => Promise<void>;
	uploadSensorConfigAsTemplate?: (configName: string) => Promise<void>;
	onClose?: () => void;
}

const SensorTemplateExportComponent: React.FC<SensorTemplateExportProps> = (props: SensorTemplateExportProps) => {
	const [loading, setLoading] = useState(false);
	const [showSuccess, setShowSuccess] = useState(false);
	const [showError, setShowError] = useState(false);

	const exportConfig = async (action: (configName: string) => Promise<void>) => {
		try {
			setShowError(false);
			setShowSuccess(false);
			setLoading(true);
			action && (await action(props.configName));
			setShowSuccess(true);
		} catch (e) {
			setShowError(true);
		}
		setLoading(false);
	};

	const handleClose = () => {
		setShowSuccess(false);
		setShowError(false);
		props.onClose && props.onClose();
	};

	return (
		<Modal
			title={<Trans i18nKey={'sensorWizard.steps.export_config'} values={{configName: props.configName}} />}
			open={props.isVisible}
			onOk={handleClose}
			onCancel={handleClose}
		>
			<Spin spinning={loading}>
				<div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
					<Button
						onClick={async () => {
							await exportConfig(props.exportSensorConfigAsJson);
						}}
					>
						<Trans i18nKey={'sensorWizard.steps.download_config'} />
					</Button>
					<Button
						onClick={async () => {
							await exportConfig(props.uploadSensorConfigAsTemplate);
						}}
					>
						<Trans i18nKey={'sensorWizard.steps.upload_config'} />
					</Button>
					{showSuccess && (
						<Alert
							type="success"
							message={
								<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
									<Trans i18nKey={'sensorWizard.steps.export_config_success'} />
									<Button onClick={() => navigator.clipboard.writeText(props.configName)}>
										<Trans i18nKey={'sensorWizard.steps.config_name_copy_to_clipboard'} />
									</Button>
								</div>
							}
						/>
					)}
					{showError && <Alert type="error" message={<Trans i18nKey={'sensorWizard.steps.export_config_failure'} />} />}
				</div>
			</Spin>
		</Modal>
	);
};

export default SensorTemplateExportComponent;
