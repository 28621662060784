import React from 'react';
import {Space, Tag} from 'antd';
import {AntButton} from '../../../Shared/UI/Ant';
import {ROLE_TAG_COLOR} from '../Constants';

export const UserColumns = (sendActivationMail, deleteUser, changeUserSetting, showAccessSettings, deleteMFAUserSetting) => {
	return [
		{
			title: 'Role',
			dataIndex: 'access_settings',
			key: 'access_settings',
			render: access_settings => {
				return access_settings.settings.roles.map(tag => {
					let color = '';

					if (tag === 'basic') {
						color = ROLE_TAG_COLOR.BASIC;
					}

					if (tag === 'operator') {
						color = ROLE_TAG_COLOR.OPERATOR;
					}

					if (tag === 'operatorplus') {
						color = ROLE_TAG_COLOR.OPERATORPLUS;
					}

					return (
						<Tag color={color} key={tag}>
							{tag.toUpperCase()}
						</Tag>
					);
				});
			},
		},
		{
			title: 'Active',
			align: 'center',
			render: record => {
				const active = record.access_settings.settings.active ? '#52c41a' : '#f5222d';
				return (
					<div
						style={{
							margin: '0 auto',
							borderRadius: '50%',
							width: '8px',
							height: '8px',
							backgroundColor: active,
						}}
					></div>
				);
			},
		},
		{
			title: 'Access',
			align: 'center',
			render: record => {
				const access = record.access_settings.settings.access ? '#52c41a' : '#f5222d';
				return (
					<div
						style={{
							margin: '0 auto',
							borderRadius: '50%',
							width: '8px',
							height: '8px',
							backgroundColor: access,
						}}
					/>
				);
			},
		},
		{
			title: 'First name',
			dataIndex: 'first_name',
			key: 'firstName',
		},
		{
			title: 'Last name',
			dataIndex: 'last_name',
			key: 'lastName',
		},
		{
			title: 'Email/Login',
			dataIndex: 'email',
			key: 'email',
			render: record => {
				return (
					<div style={{display: 'grid', placeItems: 'stretch'}}>
						<div
							style={{
								whiteSpace: 'nowrap',
								textOverflow: 'ellipsis',
								overflow: 'hidden',
							}}
						>
							{record}
						</div>
					</div>
				);
			},
		},
		{
			title: 'Access Settings',
			key: 'access',
			render: record => (
				<Space size="small">
					<AntButton onClick={() => showAccessSettings(record)}>Show Access Settings</AntButton>
				</Space>
			),
		},
		{
			title: 'Action',
			key: 'action',
			render: record => {
				// {
				//     <Space size="small">
				//         <AntButton onClick={() => sendActivationMail(record)} disabled={record.active}>
				//             Send activation
				//         </AntButton>
				// }
				if (record.access_settings) {
					const active = !record.access_settings.settings.active;
					const access = record.access_settings.settings.access;
					const mfaOn = record.access_settings.mfaEnabled || record.access_settings.mfaSecret;

					return (
						<Space>
							{mfaOn ? (
								<AntButton onClick={() => deleteMFAUserSetting(record)} disabled={!mfaOn} style={{minWidth: '130px'}}>
									Reset 2FA
								</AntButton>
							) : (
								''
							)}
							<AntButton
								onClick={() =>
									changeUserSetting(record, {
										key: 'settings',
										setting: 'access',
										value: !record.access_settings.settings.access,
									})
								}
								disabled={active}
								style={{minWidth: '130px'}}
							>
								{access ? 'Disable Access' : ' Enable Access'}
							</AntButton>
							<AntButton id={record.first_name} danger={true} onClick={() => deleteUser(record)}>
								Delete
							</AntButton>
						</Space>
					);
				}
			},
		},
	];
};
