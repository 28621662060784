import React from 'react';
import {Checkbox} from 'antd';
import {Trans} from 'react-i18next';

export const AccessElementColumns = (onChangePageItemAccess, record, canModifyAccess) => {
	return [
		{
			title: 'Item',
			dataIndex: 'key',
			key: 'key',
			render: (text, record) => {
				if (record.type === 'hidden') {
					return null;
				} else {
					return <Trans i18nKey={'settings.userManagement.' + record.key} />;
				}
			},
		},
		{
			title: 'Allow',
			dataIndex: 'allow',
			key: 'allow',
			align: 'center',
			width: '90px',
			render: (text, record) => {
				if (record.type === 'hidden' || record.type === 'group') {
					return null;
				} else {
					return (
						<Checkbox disabled={!canModifyAccess} checked={record.allow} onChange={e => onChangePageItemAccess(e, record)} />
					);
				}
			},
		},
	];
};
