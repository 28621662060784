import React, {Component} from 'react';
import {Button} from 'reactstrap';
import ContentWrapper from '../Layout/ContentWrapper';
import {Trans, useTranslation, withTranslation} from 'react-i18next';
import AuditTrailService from './AuditTrailService';
import AuditTrailEventTypes from './AuditTrailEventTypes';
import Highlighter from 'react-highlight-words';
import {Input, Select} from 'antd';
import {SearchOutlined} from '@ant-design/icons';
import AccordionFilter from '../Common/Filter/AccordionFilter';
import pubsub from 'pubsub-js';
import DateTimeUtils from '../Infrastructure/DateTime/DateTimeUtils';
import {HotKeys} from 'react-hotkeys';
import DebugReport from '../Shared/Components/DebugReport';
import Shared from '../Shared/Shared';
import fileSaver from 'file-saver';
import Report from '../Shared/Components/Report';
import {AntStyledTable} from '../Shared/Styles/AntStyledTable';
import KnowledgeHelp from '../Shared/Components/KnowledgeBaseHelp';
import {StyledHeaderHelpIcon} from '../Shared/Styles/StyledHeaderHelpIcon';
import {Access} from '../Infrastructure/Authorization/Components';
import {accessPermissions, userRoles} from '../Infrastructure/Authorization/Access';
import RequestLogger from '../Infrastructure/Requests/Logger/RequestLogger';
import {EventDetailList} from '../Event/EventDetailList';
import {EventDetail} from '../../common/types';

const Option = Select.Option;
const {t} = useTranslation();
const keys = {debugAuditTrailReport: 'alt+r'};
const handlers = {debugAuditTrailReport: event => pubsub.publish('debugAuditTrailReport')};

const filterTypes = {
	Equal: 0,
	Between: 1,
	GreatherThan: 2,
	LessThan: 3,
	List: 4,
	Above: 2,
	Below: 3,
};

const incidentFilterPlaceholder = {
	equal: 'ex. 1200',
	between: 'ex. 1200-1400',
	list: 'ex. 1200,1300,1400',
	above: 'ex. 1200',
	below: 'ex. 1200',
};

class AuditTrail extends Component {
	constructor(props) {
		super(props);

		const last7Days = DateTimeUtils.getLast7Days();

		this.state = {
			newEventsCounter: 0,
			data: [],
			pagination: {
				showSizeChanger: false,
			},
			loading: false,
			searchText: '',
			searchTextIncident: '',
			expandedKeys: [],
			incident_filter: 'equal',
			dateFrom: last7Days.firstDayLast7Days,
			dateTo: last7Days.lastDayLast7Days,
			showAll: false,
			current_table_filter_sorting: {},
			debugAuditTrailReport: false,
			loadingReport: false,
		};

		this.eventTypeFilter = React.createRef();
	}

	componentDidMount() {
		this.fetch({}, 'onLoad');
		this.handleAuditTrailEntryToken = pubsub.subscribe('audit_trail_new', this.handleAuditTrailEntry);
		this.debugAuditTrailReportToken = pubsub.subscribe('debugAuditTrailReport', () =>
			this.setState({debugAuditTrailReport: !this.state.debugAuditTrailReport})
		);
	}

	componentWillUnmount() {
		pubsub.unsubscribe(this.handleAuditTrailEntryToken);
		pubsub.unsubscribe(this.debugAuditTrailReportToken);
	}

	handleAuditTrailEntry = (message, data) => {
		this.setState({newEventsCounter: this.state.newEventsCounter + 1});
	};

	incidentIdSearchProps = dataIndex => ({
		filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
			<div style={{padding: 8}}>
				<div>
					<Select id="incidentSearchFilter" defaultValue="equal" style={{width: 100}} onChange={this.handleIncidentFilterChange}>
						<Option value="equal">Equal</Option>
						<Option value="between">Between</Option>
						<Option value="list">List</Option>
						<Option value="above">Above</Option>
						<Option value="below">Below</Option>
					</Select>
					<p />
					<Input
						id="incidentSearchValue"
						ref={node => (this.searchInput = node)}
						placeholder={incidentFilterPlaceholder[this.state.incident_filter]}
						value={this.state.searchTextIncident}
						onChange={e => setSelectedKeys(this.changeIncidentFilterValue(e.target.value, this.state.incident_filter))}
						style={{width: 188, marginBottom: 8, display: 'block', height: '32px'}}
					/>
				</div>
				<Button
					id="incidentSearchButton"
					type="primary"
					onClick={() => confirm()}
					icon="search"
					size="small"
					style={{width: 90, marginRight: 8}}
				>
					Search
				</Button>
				<Button
					id="incidentSearchReset"
					onClick={() => {
						clearFilters();
						this.setState({searchTextIncident: ''});
					}}
					size="small"
					style={{width: 90}}
				>
					Reset
				</Button>
			</div>
		),
		filterIcon: filtered => <SearchOutlined id="incidentSearchIcon" type="search" style={{color: filtered ? '#1890ff' : undefined}} />,
		onFilterDropdownOpenChange: visible => {
			if (visible) {
				setTimeout(() => this.searchInput.select());
			}
		},
	});

	changeIncidentFilterValue = (value, filter) => {
		if (filter === 'between') {
			if (!/^[0-9]{1,}-?[0-9]{0,}$/.test(value)) {
				return value;
			}
		}

		if (filter === 'list') {
			if (!/^([0-9,])+(\d+)*$/.test(value)) {
				return value
					.replace(/[^\d,]/g, '')
					.split(',')
					.filter(e => e)
					.join(',');
			}
		}

		if (filter === 'equal' || filter === 'above' || filter === 'below') {
			if (!/^\d+$/.test(value)) {
				return [value.replace(/[^\d]/g, '')];
			}
		}

		this.setState({searchTextIncident: value});

		return [value];
	};

	getDropdownSearchProps = dataIndex => ({
		filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
			<div style={{padding: 8}}>
				<Select
					id="eventSearchValue"
					ref={this.eventTypeFilter}
					mode="tags"
					style={{width: '100%'}}
					onChange={e => setSelectedKeys(e)}
					value={selectedKeys}
					tokenSeparators={[',']}
				>
					{AuditTrailEventTypes.filter(detailType => {
						return detailType.type > 0;
					}).map(detailType => (
						<Option key={detailType.type} value={t(AuditTrailEventTypes[detailType.type].translate)}>
							<Trans i18nKey={'auditTrail.events.' + detailType.translate} />
						</Option>
					))}
				</Select>
				<p />
				<Button
					type="primary"
					onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					icon="search"
					size="small"
					style={{width: 90, marginRight: 8}}
				>
					Search
				</Button>
				<Button
					onClick={() => {
						clearFilters();
					}}
					size="small"
					style={{width: 90}}
				>
					Reset
				</Button>
			</div>
		),
		filterIcon: filtered => <SearchOutlined id="eventSearchIcon" type="search" style={{color: filtered ? '#1890ff' : undefined}} />,
		onFilterDropdownOpenChange: visible => {
			/*if (visible) {
                setTimeout(() => this.searchInput.select());
            }*/
		},
	});

	getColumnSearchProps = dataIndex => ({
		filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
			<div style={{padding: 8}}>
				<Input
					ref={node => (this.searchInput = node)}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
					style={{width: 188, marginBottom: 8, display: 'block', height: '32px'}}
				/>
				<Button
					type="primary"
					onClick={() => this.handleSearch(selectedKeys, confirm)}
					icon="search"
					size="small"
					style={{width: 90, marginRight: 8}}
				>
					Search
				</Button>
				<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{width: 90}}>
					Reset
				</Button>
			</div>
		),
		filterIcon: filtered => <SearchOutlined id="userSearchIcon" type="search" style={{color: filtered ? '#1890ff' : undefined}} />,
		onFilter: (value, record) => record[dataIndex] && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
		onFilterDropdownOpenChange: visible => {
			if (visible) {
				setTimeout(() => this.searchInput.select());
			}
		},
		render: text => (
			<Highlighter
				highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
				searchWords={[this.state.searchText]}
				autoEscape
				textToHighlight={text.toString()}
			/>
		),
	});

	handleIncidentFilterChange = value => {
		this.setState({incident_filter: value, searchTextIncident: ''});
	};

	handleSearch = (selectedKeys, confirm) => {
		confirm();
		this.setState({searchText: selectedKeys[0]});
	};

	handleReset = clearFilters => {
		clearFilters();
		this.setState({searchText: ''});
	};

	columns = [
		{
			title: 'Incident #',
			key: 'incidents_id',
			width: '12%',
			render: incident => {
				return <span id={incident.index}>{incident.incidents_id}</span>;
			},
			sorter: (a, b) => a.incidents_id - b.incidents_id,
			...this.incidentIdSearchProps('incidents_id'),
		},
		{
			title: 'Date/Time',
			dataIndex: 'date_occurred',
			width: '15%',
			sorter: true,
			render: date => <span>{DateTimeUtils.getDateTimeInUserTZ(date)}</span>,
		},
		{
			title: 'Event',
			dataIndex: 'type',
			render: type => (
				<span className={t(AuditTrailEventTypes[type].translate)}>
					<Trans i18nKey={this.getTransKey(type)} />
				</span>
			),
			sorter: (a, b) => {
				return t(AuditTrailEventTypes[a.type].translate).localeCompare(t(AuditTrailEventTypes[b.type].translate));
			},
			...this.getDropdownSearchProps('type_filter'),
		},
		{
			title: 'Sensor',
			dataIndex: 'sensor',
			...this.getColumnSearchProps('sensor'),
		},
		{
			title: 'User',
			dataIndex: 'user',
			...this.getColumnSearchProps('user'),
		},
	];

	getTransKey(type) {
		return 'auditTrail.events.' + AuditTrailEventTypes[type].translate;
	}

	getSensorFromDetail(details) {
		let value = '--';
		if (details !== null && details !== undefined) {
			details.forEach(function (detail) {
				if (detail.type === 9) {
					value = detail.value;
				}
			});
		}
		return value;
	}

	getUserFromDetail(details) {
		let value = 'System';
		if (details !== null && details !== undefined) {
			details.forEach(function (detail) {
				if (detail.type === 10) {
					value = detail.value;
				}
			});
		}
		return value;
	}

	handleTableChange = (pagination, filters, sorter) => {
		const pager = {...this.state.pagination};
		pager.current = pagination.current;
		this.setState({pagination: pager});

		this.fetch(
			{
				results: pagination.pageSize,
				page: pagination.current,
				sortField: sorter.field,
				sortOrder: sorter.order,
				...filters,
			},
			'onClick'
		);

		this.setTableFilterAndSorting(filters, this.state.incident_filter, sorter);
	};

	setTableFilterAndSorting = (filters, incidentFilterType, sorter) => {
		const current_table_filter_sorting = {};

		// sorting
		if (Object.keys(sorter).length > 0) {
			const direction = sorter.order === 'ascend' ? 0 : 1;
			current_table_filter_sorting.sort = {column: sorter.field, sortDirection: direction};
		}

		// filters
		const columnFilters = [];
		for (let [key, value] of Object.entries(filters)) {
			let filterType = '';

			if (key === 'incidents_id' && value !== null) {
				filterType = filterTypes[incidentFilterType && incidentFilterType[0].toUpperCase() + incidentFilterType.slice(1)];

				// list
				if (incidentFilterType === 4) {
					value = value.toString().replace(/[,-]/g, ';').split(';');
				}

				// between
				if (filterType === 1) {
					value = value.toString().replace(/[,-]/g, ';').split(';');
				}

				if (filterType === undefined) {
					filterType = 4;
				}
			} else {
				filterType = 4;
			}

			if (key === 'sensor') {
				filterType = 5; // set type 5 for report service
			}

			if (key === 'type' && value !== null) {
				// get translation key and return values as list
				value = value.map(val => {
					const eventType = AuditTrailEventTypes.find(type => type.translate === val);
					if (eventType) {
						return eventType.translate;
					} else {
						return val;
					}
				});
			}

			if (value !== null) {
				const columnFilter = {
					column: key,
					filterType: filterType,
					filterValue: value,
				};

				columnFilters.push(columnFilter);
			}
		}

		// set col filters
		current_table_filter_sorting.columnFilters = columnFilters;

		this.setState({current_table_filter_sorting});
	};

	reload = () => {
		this.setState({incident_filter: undefined, searchText: '', newEventsCounter: 0});
		this.fetch({}, 'onClick');
	};

	fetch = (params = {}, eventTrigger) => {
		this.setState({loading: true});

		params.incident_filter = this.state.incident_filter;

		const from = DateTimeUtils.getIsoFromUCTWithoutOffset(this.state.dateFrom, DateTimeUtils.getCurrentUserTZOffset());
		const to = DateTimeUtils.getIsoFromUCTWithoutOffset(this.state.dateTo, DateTimeUtils.getCurrentUserTZOffset());

		if (!this.state.showAll) {
			params.from = from;
			params.to = to;
		}

		AuditTrailService.events(params, RequestLogger.createLogData('audit-trail', 'load-events', eventTrigger))
			.then(response => {
				let events = response.data;
				events.forEach((event, index) => {
					event.index = index;
					event.sensor = this.getSensorFromDetail(event.details);
					event.user = this.getUserFromDetail(event.details);
				});

				this.setState({
					loading: false,
					data: events,
				});
			})
			.catch(error => {
				this.setState({loading: false});
			});
	};

	showLast7Days = e => {
		let last7Days = DateTimeUtils.getLast7Days();
		this.setState(
			{
				dateFrom: last7Days.firstDayLast7Days,
				dateTo: last7Days.lastDayLast7Days,
				showAll: false,
			},
			() => {
				this.showSelectedDateTime();
			}
		);

		e.preventDefault();
	};

	showLastYear = e => {
		let lastYear = DateTimeUtils.getLastYear();
		this.setState(
			{
				dateFrom: lastYear.firstDayLastYear,
				dateTo: lastYear.lastDayLastYear,
				showAll: false,
			},
			() => {
				this.showSelectedDateTime();
			}
		);
		e.preventDefault();
	};

	showLastMonth = e => {
		let lastMonth = DateTimeUtils.getLastMonth();
		this.setState(
			{
				dateFrom: lastMonth.firstDayLastMonth,
				dateTo: lastMonth.lastDayLastMonth,
				showAll: false,
			},
			() => {
				this.showSelectedDateTime();
			}
		);
		e.preventDefault();
	};

	showLastWeek = e => {
		let lastWeek = DateTimeUtils.getLastWeek();
		this.setState(
			{
				dateFrom: lastWeek.firstDayLastWeek,
				dateTo: lastWeek.lastDayLastWeek,
				showAll: false,
			},
			() => {
				this.showSelectedDateTime();
			}
		);
		e.preventDefault();
	};

	showAll = e => {
		this.setState(
			{
				showAll: true,
			},
			() => {
				this.showSelectedDateTime();
			}
		);
		e.preventDefault();
	};

	report = e => {
		this.setState({loadingReport: true});
		AuditTrailService.auditTrailReport(this.getReportPayload(), RequestLogger.createLogData('audit-trail', 'create-report', 'onClick'))
			.then(response => {
				var contentDisposition = response.headers['content-disposition'];
				var match = contentDisposition.match(/filename\s*=\s*(.+);/i);
				var filename = match[1];

				const file = Shared.base64toFile(response.data, '', 'application/pdf');
				fileSaver.saveAs(file, filename);
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => this.setState({loadingReport: false}));

		e.preventDefault();
	};

	getReportPayload = () => {
		let payload = this.state.current_table_filter_sorting;

		payload.columnFilters = !payload.columnFilters ? [] : payload.columnFilters;
		payload.sort = !payload.sort ? {column: 'date_occurred', sortDirection: 1} : payload.sort;

		payload.timeFilter = {
			from: DateTimeUtils.getIsoFromUCTWithoutOffset(this.state.dateFrom, DateTimeUtils.getCurrentUserTZOffset()),
			to: DateTimeUtils.getIsoFromUCTWithoutOffset(this.state.dateTo, DateTimeUtils.getCurrentUserTZOffset()),
		};

		return payload;
	};

	showSelectedDateTime = () => this.fetch({}, 'onClick');

	onTableRowExpand = (expanded, record) => {
		const keys = [];
		if (expanded) {
			keys.push(record.id);
		}
		this.setState({expandedKeys: keys});
	};

	render() {
		return (
			<HotKeys keyMap={keys} handlers={handlers}>
				<ContentWrapper>
					<div className="content-heading">
						<div>Audit Trail</div>
						<StyledHeaderHelpIcon>
							<KnowledgeHelp id="030" />
						</StyledHeaderHelpIcon>
						<div className="ml-auto">
							{this.state.debugAuditTrailReport ? (
								<DebugReport url={'http://localhost:53404/report/auditTrail'} payload={this.getReportPayload()} />
							) : null}
							<Access access={accessPermissions.auditTrail.child.createReport} roles={userRoles.default}>
								<Report onClick={this.report} loadingReport={this.state.loadingReport} />
							</Access>
						</div>
					</div>

					<AccordionFilter
						title={'Filter and Sorting'}
						expanded={true}
						dateFrom={this.state.dateFrom}
						setDateFrom={value => this.setState({dateFrom: value})}
						dateTo={this.state.dateTo}
						setDateTo={value => this.setState({dateTo: value})}
						showLastYear={this.showLastYear}
						showLastWeek={this.showLastWeek}
						showLastMonth={this.showLastMonth}
						showLast7Days={this.showLast7Days}
						showAll={this.showAll}
						showSelectedDateTime={this.showSelectedDateTime}
					/>

					<br />

					<AntStyledTable
						showSorterTooltip={false}
						locale={{emptyText: 'No data'}}
						columns={this.columns}
						rowKey={record => record.id}
						dataSource={this.state.data}
						pagination={this.state.pagination}
						loading={this.state.loading}
						onChange={this.handleTableChange}
						expandedRowKeys={this.state.expandedKeys}
						onExpand={this.onTableRowExpand}
						expandable={{
							expandRowByClick: true,
							expandedRowRender: (record, index, indent, expanded) =>
								record.details && <EventDetailList eventDetails={record.details.map(d => new EventDetail(d))} />,
						}}
					/>
				</ContentWrapper>
			</HotKeys>
		);
	}
}

export default withTranslation()(AuditTrail);
