import React from 'react';
import {Checkbox} from 'antd';
import {Trans} from 'react-i18next';

export const AccessPageColumns = (onChangePageAccess, canModifyAccess) => {
	return [
		{
			title: 'Page',
			dataIndex: 'key',
			key: 'key',
			render: (text, record) => {
				return <Trans i18nKey={'settings.userManagement.' + record.key} />;
			},
		},
		{
			title: 'Allow',
			dataIndex: 'allow',
			key: 'allow',
			align: 'center',
			width: '90px',
			render: (text, record) => {
				return <Checkbox disabled={!canModifyAccess} checked={record.allow} onChange={e => onChangePageAccess(e, record)} />;
			},
		},
	];
};
