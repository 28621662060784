import React from 'react';
import {CarryOutOutlined} from '@ant-design/icons';
import {AccessPermissions} from '../../../../Infrastructure/Authorization/Access';
import {Organization} from '../../../../../common/types';

interface ITreeItem {
	title: string;
	key: string;
	path: string[];
	type: 'page' | 'node';
	icon: React.ReactElement;
	children?: ITreeItem[];
}

export const GetAccessSettingsTree = (organization: Organization): ITreeItem[] => {
	const tree: ITreeItem[] = [
		{
			title: 'Devices View',
			key: '0-0',
			path: ['devicesview'],
			type: 'page',
			icon: <CarryOutOutlined />,
			children: [
				{
					title: 'Dashboard',
					key: '0-0-0',
					path: ['devicesview', 'dashboard'],
					type: 'page',
					icon: <CarryOutOutlined />,
				},
				{
					title: 'List View',
					key: '0-0-1',
					path: ['devicesview', 'datagrid'],
					type: 'page',
					icon: <CarryOutOutlined />,
				},
			],
		},
		{
			title: 'Deviations',
			key: '0-1',
			path: ['deviations'],
			type: 'page',
			icon: <CarryOutOutlined />,
		},
		{
			title: 'Audit Trail',
			key: '0-3',
			path: ['auditTrail'],
			type: 'page',
			icon: <CarryOutOutlined />,
		},
		{
			title: 'Calibrations',
			key: '0-4',
			path: ['calibrations'],
			type: 'page',
			icon: <CarryOutOutlined />,
		},
		{
			title: 'Settings',
			key: '0-5',
			path: ['settings'],
			type: 'node',
			icon: <CarryOutOutlined />,
			children: [
				{
					title: 'Sensor Groups',
					key: '0-5-0',
					path: ['settings', 'sensorGroups'],
					type: 'page',
					icon: <CarryOutOutlined />,
				},
				{
					title: 'Limit Profiles',
					key: '0-5-1',
					path: ['settings', 'limitProfiles'],
					type: 'page',
					icon: <CarryOutOutlined />,
				},
				{
					title: 'Issue Profiles',
					key: '0-5-2',
					path: ['settings', 'issueProfiles'],
					type: 'page',
					icon: <CarryOutOutlined />,
				},
				{
					title: 'Licences',
					key: '0-5-3',
					path: ['settings', 'licenses'],
					type: 'page',
					icon: <CarryOutOutlined />,
				},
				{
					title: 'Invoices & Cards',
					key: '0-5-4',
					path: ['settings', 'billing'],
					type: 'page',
					icon: <CarryOutOutlined />,
				},
				{
					title: 'Organization',
					key: '0-5-5',
					path: ['settings', 'organization'],
					type: 'page',
					icon: <CarryOutOutlined />,
				},
				{
					title: 'User Profile',
					key: '0-5-6',
					path: ['settings', 'userProfile'],
					type: 'page',
					icon: <CarryOutOutlined />,
				},
				{
					title: 'User Management',
					key: '0-5-7',
					path: ['settings', 'userManagement'],
					type: 'page',
					icon: <CarryOutOutlined />,
				},
			],
		},
	];

	if (organization.Config.global.predictiveEnabled) {
		tree.find(t => t.title == 'Settings')?.children?.push({
			title: 'elproPREDICT',
			key: '0-5-8',
			path: AccessPermissions.settings.child.predictive.path,
			type: 'page',
			icon: <CarryOutOutlined />,
		});
	}

	return tree;
};
