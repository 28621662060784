import request from '../Requests/Request';
import Authentication from '../../../components/Infrastructure/Authentication/Authentication';
const getBaseUrl = () => {
	let baseUrl = '';

	if (window.location.href.indexOf('demo.elpro.cloud') > -1) {
		baseUrl = 'https://api.demo.elpro.cloud/';
	} else if (window.location.href.indexOf('sandbox.elpro.cloud') > -1) {
		baseUrl = 'https://api.sandbox.elpro.cloud/';
	} else if (window.location.href.indexOf('elpro.cloud') > -1) {
		baseUrl = 'https://api.elpro.cloud/';
	} else if (window.location.href.indexOf('liberog.elpro.com') > -1) {
		baseUrl = 'https://api.elpro.cloud/';
	} else {
		baseUrl = 'https://api.elcl.xyz/';
	}

	return baseUrl;
};

const getFrontendBaseUrl = () => {
	let baseUrl = '';

	if (window.location.href.indexOf('demo.elpro.cloud') > -1) {
		baseUrl = 'https://web.demo.elpro.cloud/';
	} else if (window.location.href.indexOf('sandbox.elpro.cloud') > -1) {
		baseUrl = 'https://web.demo.elpro.cloud/';
	} else if (window.location.href.indexOf('elpro.cloud') > -1) {
		baseUrl = 'https://web.elpro.cloud/';
	} else if (window.location.href.indexOf('liberog.elpro.com') > -1) {
		baseUrl = 'https://web.elpro.cloud/';
	} else {
		baseUrl = 'https://elcl.xyz/';
	}

	return baseUrl;
};

const getExternalApiBaseUrl = () => {
	let baseUrl = '';

	if (window.location.href.indexOf('demo.elpro.cloud') > -1) {
		baseUrl = 'https://apibridge.demo.elpro.cloud/';
	} else if (window.location.href.indexOf('sandbox.elpro.cloud') > -1) {
		baseUrl = 'https://apibridge.elpro.cloud/';
	} else if (window.location.href.indexOf('elpro.cloud') > -1) {
		baseUrl = 'https://apibridge.elpro.cloud/';
	} else {
		baseUrl = 'https://demoapi.elcl.xyz/';
	}

	return baseUrl;
};

const getApplicationVersion = () => {
	return request({
		url: '/get_application_version',
		method: 'GET',
		token: Authentication.getToken(),
	});
};

const appSettings = {
	getBaseUrl,
	getApplicationVersion,
	getFrontendBaseUrl,
	getExternalApiBaseUrl,
};

export default appSettings;
