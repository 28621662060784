export const AuditTrailDetailTypes: {type: number; translate: string}[] = [
	{
		type: 0,
		translate: 'Unknown',
	},
	{
		type: 1,
		translate: 'EntityType',
	},
	{
		type: 2,
		translate: 'EntityId',
	},
	{
		type: 3,
		translate: 'PropertyChange',
	},
	{
		type: 4,
		translate: 'ErrorDetails',
	},
	{
		type: 5,
		translate: 'EntryReason',
	},
	{
		type: 6,
		translate: 'ModuleName',
	},
	{
		type: 7,
		translate: 'ModuleId',
	},
	{
		type: 8,
		translate: 'ModuleType',
	},
	{
		type: 9,
		translate: 'SensorName',
	},
	{
		type: 10,
		translate: 'FullName',
	},
	{
		type: 11,
		translate: 'UpperAlarmLimit',
	},
	{
		type: 12,
		translate: 'LowerAlarmLimit',
	},
	{
		type: 13,
		translate: 'UpperAlarmDelay',
	},
	{
		type: 14,
		translate: 'LowerAlarmDelay',
	},
	{
		type: 15,
		translate: 'SensorFailureSetting',
	},
	{
		type: 16,
		translate: 'SensorFailureDelay',
	},
	{
		type: 17,
		translate: 'MissingValueSetting',
	},
	{
		type: 18,
		translate: 'MissingValueDelay',
	},
	{
		type: 19,
		translate: 'RadioConnectionSetting',
	},
	{
		type: 20,
		translate: 'RadioConnectionThreshold',
	},
	{
		type: 21,
		translate: 'RadioConnectionDelay',
	},
	{
		type: 22,
		translate: 'LowBatterySetting',
	},
	{
		type: 23,
		translate: 'RadioConnectionValue',
	},
	{
		type: 24,
		translate: 'SensorValue',
	},
	{
		type: 25,
		translate: 'SensorUnit',
	},
	{
		type: 26,
		translate: 'MessageType',
	},
	{
		type: 27,
		translate: 'MessageChannel',
	},
	{
		type: 28,
		translate: 'MessageServer',
	},
	{
		type: 29,
		translate: 'MessageTo',
	},
	{
		type: 30,
		translate: 'MessageCc',
	},
	{
		type: 31,
		translate: 'MessageSubject',
	},
	{
		type: 32,
		translate: 'MessageText',
	},
	{
		type: 33,
		translate: 'SendQueueRetryCount',
	},
	{
		type: 34,
		translate: 'SensorLimitProfileName',
	},
	{
		type: 35,
		translate: 'SensorLimitProfileUpperLimit',
	},
	{
		type: 36,
		translate: 'SensorLimitProfileLowerLimit',
	},
	{
		type: 37,
		translate: 'SensorLimitProfileUpperDelay',
	},
	{
		type: 38,
		translate: 'SensorLimitProfileLowerDelay',
	},
	{
		type: 39,
		translate: 'SensorChannel',
	},
	{
		type: 40,
		translate: 'SensorStatus',
	},
	{
		type: 41,
		translate: 'SensorLoggingInterval',
	},
	{
		type: 42,
		translate: 'SensorCustomerId',
	},
	{
		type: 43,
		translate: 'SensorMemo',
	},
	{
		type: 44,
		translate: 'SensorAlarmLimitProfile',
	},
	{
		type: 45,
		translate: 'SensorAlarmLimitRecipientProfile',
	},
	{
		type: 46,
		translate: 'SensorIssuesProfile',
	},
	{
		type: 47,
		translate: 'SensorIssuesRecipientProfile',
	},
	{
		type: 48,
		translate: 'SensorIssueProfileName',
	},
	{
		type: 49,
		translate: 'SensorIssueProfileMissingValueAlarm',
	},
	{
		type: 50,
		translate: 'SensorIssueProfileMissingValueAlarmDelay',
	},
	{
		type: 51,
		translate: 'SensorIssueProfileSensorFailureAlarm',
	},
	{
		type: 52,
		translate: 'SensorIssueProfileSensorFailureAlarmDelay',
	},
	{
		type: 53,
		translate: 'SensorIssueProfileRadioConnectionWarning',
	},
	{
		type: 54,
		translate: 'SensorIssueProfileRadioConnectionWarningThreshold',
	},
	{
		type: 55,
		translate: 'SensorIssueProfileRadioConnectionWarningDelay',
	},
	{
		type: 56,
		translate: 'SensorIssueProfileLowBatteryWarning',
	},
	{
		type: 57,
		translate: 'OrganizationName',
	},
	{
		type: 58,
		translate: 'OrganizationCountry',
	},
	{
		type: 59,
		translate: 'OrganizationZIP',
	},
	{
		type: 60,
		translate: 'OrganizationTown',
	},
	{
		type: 61,
		translate: 'OrganizationUID',
	},
	{
		type: 62,
		translate: 'OrganizationID',
	},
	{
		type: 63,
		translate: 'UserFirstName',
	},
	{
		type: 64,
		translate: 'UserLastName',
	},
	{
		type: 65,
		translate: 'UserEmail',
	},
	{
		type: 66,
		translate: 'UserMobile',
	},
	{
		type: 67,
		translate: 'SensorLicenseModelType',
	},
	{
		type: 68,
		translate: 'SensorId',
	},
	{
		type: 69,
		translate: 'CreditCardNumber',
	},
	{
		type: 70,
		translate: 'CreditCardExpirationDate',
	},
	{
		type: 71,
		translate: 'TriggerType',
	},
	{
		type: 72,
		translate: 'OrganizationAddress',
	},
	{
		type: 73,
		translate: 'UserHash',
	},
	{
		type: 74,
		translate: 'UserResetToken',
	},
	{
		type: 75,
		translate: 'LimitEmailNotification',
	},
	{
		type: 76,
		translate: 'LimitSmsNotification',
	},
	{
		type: 77,
		translate: 'IssueEmailNotification',
	},
	{
		type: 78,
		translate: 'IssueSmsNotification',
	},
	{
		type: 79,
		translate: 'OrganisationSessionTimeout',
	},
	{
		type: 80,
		translate: 'UserProfileTimezone',
	},
	{
		type: 81,
		translate: 'UserProfileLanguage',
	},
	{
		type: 82,
		translate: 'ExpirationDays',
	},
	{
		type: 83,
		translate: 'Enabled',
	},
	{
		type: 84,
		translate: 'NewEmailRequestHash',
	},
	{
		type: 85,
		translate: 'MeansOfPayment',
	},
	{
		type: 86,
		translate: 'NewSerialNumber',
	},
	{
		type: 87,
		translate: 'CalibrationExpiryModules',
	},
	{
		type: 88,
		translate: 'RPCPath',
	},
	{
		type: 89,
		translate: 'UserID',
	},
	{
		type: 90,
		translate: 'SensorLimitProfileUpperLimit2',
	},
	{
		type: 91,
		translate: 'SensorLimitProfileUpperLimit3',
	},
	{
		type: 92,
		translate: 'SensorLimitProfileUpperLimit4',
	},
	{
		type: 93,
		translate: 'SensorLimitProfileLowerLimit2',
	},
	{
		type: 94,
		translate: 'SensorLimitProfileLowerLimit3',
	},
	{
		type: 95,
		translate: 'SensorLimitProfileUpperLimitDelay2',
	},
	{
		type: 96,
		translate: 'SensorLimitProfileUpperLimitDelay3',
	},
	{
		type: 97,
		translate: 'SensorLimitProfileUpperLimitDelay4',
	},
	{
		type: 98,
		translate: 'SensorLimitProfileLowerLimitDelay2',
	},
	{
		type: 99,
		translate: 'SensorLimitProfileLowerLimitDelay3',
	},
	{
		type: 100,
		translate: 'SensorLimitProfileUpperLimitWarning',
	},
	{
		type: 101,
		translate: 'SensorLimitProfileLowerLimitWarning',
	},
	{
		type: 102,
		translate: 'SensorLimitProfileUpperLimitWarningDelay',
	},
	{
		type: 103,
		translate: 'SensorLimitProfileLowerLimitWarningDelay',
	},
	{
		type: 104,
		translate: 'TestEmailText',
	},
	{
		type: 105,
		translate: 'TestSmsText',
	},
	{
		type: 106,
		translate: 'SensorIssueProfileLostMeasurementAlarm',
	},
	{
		type: 107,
		translate: 'SensorIssueProfileMissingCommunicationWarning',
	},
	{
		type: 108,
		translate: 'SensorIssueProfileMissingCommunicationWarningEnum',
	},
	{
		type: 109,
		translate: 'InternalProductId',
	},
	{
		type: 110,
		translate: 'SubscriptionStatus',
	},
	{
		type: 113,
		translate: 'CalibrationId',
	},
	{
		type: 114,
		translate: 'CalibrationDuration',
	},
	{
		type: 115,
		translate: 'CalibrationStopReason',
	},
	{
		type: 116,
		translate: 'UpperAlarmLimit2',
	},
	{
		type: 117,
		translate: 'UpperAlarmLimit3',
	},
	{
		type: 118,
		translate: 'UpperAlarmLimit4',
	},
	{
		type: 119,
		translate: 'LowerAlarmLimit2',
	},
	{
		type: 120,
		translate: 'LowerAlarmLimit3',
	},
	{
		type: 121,
		translate: 'LimitZoneType',
	},
	{
		type: 122,
		translate: 'UpperAlarmDelay2',
	},
	{
		type: 123,
		translate: 'UpperAlarmDelay3',
	},
	{
		type: 124,
		translate: 'UpperAlarmDelay4',
	},
	{
		type: 125,
		translate: 'LowerAlarmDelay2',
	},
	{
		type: 126,
		translate: 'LowerAlarmDelay3',
	},
	{
		type: 127,
		translate: 'LimitEmailList',
	},
	{
		type: 128,
		translate: 'LimitSmsList',
	},
	{
		type: 129,
		translate: 'IssueEmailList',
	},
	{
		type: 130,
		translate: 'IssueSmsList',
	},
	{
		type: 131,
		translate: 'UsePrepaidCredit',
	},
	{
		type: 132,
		translate: 'QuickViewAllowed',
	},
	{
		type: 133,
		translate: 'MfaRequired',
	},
	{
		type: 134,
		translate: 'DefaultTemperature',
	},
	{type: 135, translate: 'ProfileLength'},
	{type: 136, translate: 'ProfileEnd'},
	{type: 137, translate: 'TimestampFirstMeasurement'},
	{type: 138, translate: 'TimestampLastMeasurement'},
	{type: 139, translate: 'TimestampDelta'},
	{type: 140, translate: 'OrganizationConfig'},
];

export default AuditTrailDetailTypes;
