import axios from 'axios';
import {getOrganizationSetting as getOrgaSettings, loadUsersDataSource as loadUser} from '../UserManagement/UserMangementService';
import Authentication from '../../Infrastructure/Authentication/Authentication';

const isDebugMode = false;

const localHostLoadUsersDataSource = async () => {
	var response = await axios({
		url: 'http://localhost:8080/srv/v1/settings/user-management/users',
		method: 'GET',
		headers: {
			Authorization: 'Bearer ' + Authentication.getToken(),
			'Content-Type': 'application/json',
			Accept: 'text/plain',
		},
	});

	return response.data.users;
};

const localHostAddUser = async user => {
	var response = await axios({
		url: 'http://localhost:8080/srv/v1/settings/user-management/user',
		method: 'POST',
		data: {user},
		headers: {
			Authorization: 'Bearer ' + Authentication.getToken(),
			'Content-Type': 'application/json',
			Accept: 'text/plain',
		},
	});

	const {data, status, statusText} = response;
	return {data, status, statusText};
};

const localHostGetOrganizationSetting = payload => {
	return axios({
		url: 'http://localhost:8080/srv/v1/settings/organization-configuration/setting/' + payload,
		method: 'GET',
		headers: {
			Authorization: 'Bearer ' + Authentication.getToken(),
			'Content-Type': 'application/json',
			Accept: 'text/plain',
		},
	});
};

const loadUsersDataSource = () => {
	if (isDebugMode) {
		return localHostLoadUsersDataSource();
	} else {
		return loadUser();
	}
};

const getOrganizationSetting = payload => {
	if (isDebugMode) {
		return localHostGetOrganizationSetting(payload);
	} else {
		return getOrgaSettings(payload);
	}
};

const UserManagementHelper = {
	loadUsersDataSource,
	getOrganizationSetting,
};

export default UserManagementHelper;
