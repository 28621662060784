import React, {Component} from 'react';
import {Table} from 'reactstrap';
import pubsub from 'pubsub-js';
import {MODULE_FAMILIES} from '../../../../../Shared/Constants/Module';
import {SensorSummaryTableProps} from './SensorSummaryTableProps';
import {SensorSummaryTableState} from './SensorSummaryTableState';
//Sensor Basics
import SensorBasicsTitleComponent from './SensorSummaryTableParts/SensorBasicsTitleComponent';
import SensorBasicsSerialNumberComponent from './SensorSummaryTableParts/SensorBasicsSerialNumberComponent';
import SensorBasicsSensorNameComponent from './SensorSummaryTableParts/SensorBasicsSensorNameComponent';
import SensorBasicsLoginIntervalComponent from './SensorSummaryTableParts/SensorBasicsLoginIntervalComponent';
import SensorBasicsCommunicationIntervalComponent from './SensorSummaryTableParts/SensorBasicsCommunicationIntervalComponent';
import SensorBasicsFastCommunicationIntervalComponent from './SensorSummaryTableParts/SensorBasicsFastCommunicationIntervalComponent';
import SensorBasicsUnitComponent from './SensorSummaryTableParts/SensorBasicsUnitComponent';
import SensorBasicsCustomersIdComponent from './SensorSummaryTableParts/SensorBasicsCustomersIdComponent';
import SensorBasicsMemoComponent from './SensorSummaryTableParts/SensorBasicsMemoComponent';
import SensorBasicsFirmwareComponent from './SensorSummaryTableParts/SensorBasicsFirmwareComponent';
import SensorBasicsCommunicationModeComponent from './SensorSummaryTableParts/SensorBasicsCommunicationModeComponent';
//Metadatas
import MetadatasTitleComponent from './SensorSummaryTableParts/MetadatasTitleComponent';
import MetadatasSensorNameComponent from './SensorSummaryTableParts/MetadatasSensorNameComponent';
import MetadatasCustomersIdComponent from './SensorSummaryTableParts/MetadatasCustomersIdComponent';
import MetadatasMemoComponent from './SensorSummaryTableParts/MetadatasMemoComponent';
//Limit Alarms
import LimitAlarmsSummary from './SensorSummaryTableParts/LimitAlarmsSummary';
//Issue Alarms
import IssueAlarmsTitleComponent from './SensorSummaryTableParts/IssueAlarmsTitleComponent';
import IssueAlarmsUseIssueAlarmComponent from './SensorSummaryTableParts/IssueAlarmsUseIssueAlarmComponent';
import IssueAlarmsIssueNameComponent from './SensorSummaryTableParts/IssueAlarmsIssueNameComponent';
import IssueAlarmsMissingCommunicationWarningComponent from './SensorSummaryTableParts/IssueAlarmsMissingCommunicationWarningComponent';
import IssueAlarmsMissingCommunicationWarningEnumComponent from './SensorSummaryTableParts/IssueAlarmsMissingCommunicationWarningEnumComponent';
import IssueAlarmsSensorFailureAlarmComponent from './SensorSummaryTableParts/IssueAlarmsSensorFailureAlarmComponent';
import IssueAlarmsLostMeasurementComponent from './SensorSummaryTableParts/IssueAlarmsLostMeasurementComponent';
import IssueAlarmsLowBatteryWarningComponent from './SensorSummaryTableParts/IssueAlarmsLowBatteryWarningComponent';
import IssueAlarmsRecipientsComponent from './SensorSummaryTableParts/IssueAlarmsRecipientsComponent';
//Additional Configuration
import {AdditionalConfigurationSummaryComponent} from './SensorSummaryTableParts/AdditionalConfigurationSummaryComponent';
import {
	getEcologProGxAdditionalConfigurationSummary,
	getLiberoGxAdditionalConfigurationSummary,
} from './Helpers/AdditionalConfigurationComponentBuilder';
import {PredictiveFormSummary} from '../../../../../Wizard/Forms/Predictive/PredictiveFormSummary';
import {EcologProGFlightModeComponent} from '../../../../../Wizard/Forms/EcologProGInTransit/EcologProGFlightModeComponent';
import {TiltDetectionSummaryComponent} from '../../../../../Wizard/Forms/TiltDetection/TiltDetectionSummaryComponent';
import {FeatureFlagHelper} from '../../../../../../common/helpers';

class SummaryTable extends Component<SensorSummaryTableProps, SensorSummaryTableState> {
	private catchRegistryExceptionsToken = undefined;

	constructor(props: SensorSummaryTableProps | Readonly<SensorSummaryTableProps>) {
		super(props);

		this.state = {
			sensorSummarySettings: this.props.sensorSummarySettings,
			errorMessage: undefined,
			errorData: {
				column: undefined,
			},
			additionalConfigurationSummary: undefined,
			featureFlags: [],
		};

		this.catchRegistryExceptions = this.catchRegistryExceptions.bind(this);
	}

	async componentDidMount() {
		this.catchRegistryExceptionsToken = pubsub.subscribe('catchRegistryExceptions', this.catchRegistryExceptions);

		if (this.props.templateName !== undefined && this.props.templateName !== null) {
			this.setState({
				featureFlags: await FeatureFlagHelper.GetFeatureFlags(),
			});

			if (this.props.templateName === MODULE_FAMILIES.ECOLOG_PRO_G) {
				this.setState({
					additionalConfigurationSummary: getEcologProGxAdditionalConfigurationSummary(
						this.props.additional_configuration,
						this.props.configuration_template
					),
				});
			} else if (this.props.templateName === MODULE_FAMILIES.LIBERO_G) {
				this.setState({
					additionalConfigurationSummary: getLiberoGxAdditionalConfigurationSummary(
						this.props.additional_configuration,
						this.props.configuration_template
					),
				});
			}
		}
	}

	componentWillUnmount() {
		pubsub.unsubscribe(this.catchRegistryExceptionsToken);
	}

	catchRegistryExceptions(message, data) {
		this.setState({errorData: data});
	}

	hasError = field => {
		return this.state.errorData && this.state.errorData.column === field;
	};

	render() {
		return (
			<Table className="table-light" style={{color: '#000'}} responsive hover>
				<tbody>
					<SensorBasicsTitleComponent show={this.state.sensorSummarySettings.showSensorBasicsTitle} />
					<SensorBasicsSerialNumberComponent
						hasError={this.hasError}
						serialNumber={this.props.moduleForm.serial_number}
						show={this.state.sensorSummarySettings.sensorBasics.showSerialNumber}
					/>
					<SensorBasicsSensorNameComponent
						hasError={this.hasError}
						sensorName={this.props.sensorBasicsForm.sensor_name}
						show={this.state.sensorSummarySettings.sensorBasics.showSensorName}
					/>
					<SensorBasicsLoginIntervalComponent
						loggingInterval={this.props.sensorBasicsForm.logging_interval}
						show={this.state.sensorSummarySettings.sensorBasics.showLoggingInterval}
					/>
					<SensorBasicsCommunicationIntervalComponent
						communicationInterval={this.props.sensorBasicsForm.communication_interval}
						show={this.state.sensorSummarySettings.sensorBasics.showCommunicatonInterval}
					/>
					<SensorBasicsCommunicationModeComponent
						communicationMode={this.props.sensorBasicsForm.communication_mode}
						communicationModeTemplates={this.props.communication_mode_templates}
						show={this.state.sensorSummarySettings.sensorBasics.showCommunicationMode}
					/>
					<SensorBasicsFastCommunicationIntervalComponent
						fastCommunicationInterval={this.props.sensorBasicsForm.fast_communication_interval}
						optimizedCommunicationBehavior={this.props.sensorBasicsForm.optimized_communication_behavior}
						show={this.state.sensorSummarySettings.sensorBasics.showFastCommunicationInterval}
					/>
					<SensorBasicsUnitComponent
						unit={this.props.sensorBasicsForm.unit}
						show={this.state.sensorSummarySettings.sensorBasics.showUnit}
					/>
					<SensorBasicsCustomersIdComponent
						customerId={this.props.sensorBasicsForm.customer_id}
						show={this.state.sensorSummarySettings.sensorBasics.showCustomersId}
					/>
					<SensorBasicsMemoComponent
						memo={this.props.sensorBasicsForm.memo}
						show={this.state.sensorSummarySettings.sensorBasics.showMemo}
					/>
					<SensorBasicsFirmwareComponent
						firmware={this.props.sensorBasicsForm.firmware}
						show={this.state.sensorSummarySettings.sensorBasics.showFirmware}
					/>
					<MetadatasTitleComponent show={this.state.sensorSummarySettings.showMetadatasTitle} />
					<MetadatasSensorNameComponent
						hasError={this.hasError}
						sensorName={this.props.metadatasForm ? this.props.metadatasForm.sensor_name : null}
						show={this.state.sensorSummarySettings.metadatas.showSensorName}
					/>
					<MetadatasCustomersIdComponent
						customerId={this.props.metadatasForm ? this.props.metadatasForm.customer_id : null}
						show={this.state.sensorSummarySettings.metadatas.showCustomersId}
					/>
					<MetadatasMemoComponent
						memo={this.props.metadatasForm ? this.props.metadatasForm.memo : null}
						show={this.state.sensorSummarySettings.metadatas.showMemo}
					/>
					<LimitAlarmsSummary
						limitAlarmForms={this.props.formStates?.limitAlarmForms}
						loggingInterval={this.props.sensorBasicsForm.logging_interval}
						show={this.state.sensorSummarySettings.showLimitAlarmComponent}
					/>
					<IssueAlarmsTitleComponent show={this.state.sensorSummarySettings.showSensorIssueAlarmsTitle} />
					<IssueAlarmsUseIssueAlarmComponent
						useIssueAlarm={this.props.sensorIssuesForm.use_issue_alarms}
						show={this.state.sensorSummarySettings.sensorIssueAlarms.showUseIssueAlarm}
					/>
					<IssueAlarmsIssueNameComponent
						sensorIssuesForm={this.props.sensorIssuesForm}
						hasError={this.hasError}
						show={this.state.sensorSummarySettings.sensorIssueAlarms.showSensorIssueName}
					/>
					<IssueAlarmsMissingCommunicationWarningComponent
						sensorIssuesForm={this.props.sensorIssuesForm}
						show={this.state.sensorSummarySettings.sensorIssueAlarms.showMissingCommunicationWarning}
					/>
					<IssueAlarmsMissingCommunicationWarningEnumComponent
						sensorIssuesForm={this.props.sensorIssuesForm}
						show={this.state.sensorSummarySettings.sensorIssueAlarms.showMissingCommunicationWarningEnum}
					/>
					<IssueAlarmsSensorFailureAlarmComponent
						sensorIssuesForm={this.props.sensorIssuesForm}
						show={this.state.sensorSummarySettings.sensorIssueAlarms.showSensorFailureAlarm}
					/>
					<IssueAlarmsLostMeasurementComponent
						sensorIssuesForm={this.props.sensorIssuesForm}
						show={this.state.sensorSummarySettings.sensorIssueAlarms.showLostMeasurementAlarm}
					/>
					<IssueAlarmsLowBatteryWarningComponent
						sensorIssuesForm={this.props.sensorIssuesForm}
						show={this.state.sensorSummarySettings.sensorIssueAlarms.showLowBatteryWarning}
					/>
					<IssueAlarmsRecipientsComponent
						sensorIssuesForm={this.props.sensorIssuesForm}
						show={this.state.sensorSummarySettings.sensorIssueAlarms.showRecipientsForIssueAlarms}
					/>
					<AdditionalConfigurationSummaryComponent
						additionalConfigurationSummary={this.state.additionalConfigurationSummary}
						show={this.state.sensorSummarySettings.showAdditionConfigurationSummary}
					/>
					<PredictiveFormSummary
						predictiveFormState={this.props.formStates?.predictiveForm}
						outUnit={this.props.sensorBasicsForm.unit}
					/>
					{this.props.templateName === MODULE_FAMILIES.ECOLOG_PRO_G && (
						<EcologProGFlightModeComponent featureFlags={this.state.featureFlags} />
					)}

					{this.props.templateName === MODULE_FAMILIES.LIBERO_G && (
						<TiltDetectionSummaryComponent tiltDetection={this.props.additional_configuration?.tiltDetection} />
					)}
				</tbody>
			</Table>
		);
	}
}

export default SummaryTable;
