import {DevUtils} from '../util';
import {request} from '../../components/Infrastructure/Requests/Request';
import appSettings from '../../components/Infrastructure/Settings/AppSettings';
import Authentication from '../../components/Infrastructure/Authentication/Authentication';

const LocalhostPrefix: string = DevUtils.UseLocal('TEMPLATE_SERVICE') ? 'http://localhost:8080/' : appSettings.getExternalApiBaseUrl();
const Route: string = `${LocalhostPrefix}api/v1`;

export class TemplateService {
	static async UploadTemplate(name: string, serialNumberPrefix: string, config: object, orgId: number): Promise<void> {
		try {
			const payload = {
				orgId: orgId,
				name: name,
				configurationJson: JSON.stringify(config),
				supportedDeviceSerialNumberPrefix: serialNumberPrefix,
			};

			return (
				await request({
					url: `${Route}/Templates`,
					method: 'POST',
					token: Authentication.getToken(),
					data: payload,
				})
			).data;
		} catch (e) {
			return Promise.reject(new Error(e));
		}
	}
}
