export const AccessPermissions = {
	devicesview: {
		child: {
			dashboard: {
				key: 'dashboard',
				path: ['devicesview', 'dashboard'],
				child: {
					selectSensorGroups: {
						key: 'selectSensorGroups',
						path: ['devicesview', 'dashboard', 'selectSensorGroups'],
					},
					addSensor: {
						key: 'addSensor',
						path: ['devicesview', 'dashboard', 'addSensor'],
					},
					sensor: {
						key: 'sensor',
						path: ['devicesview', 'dashboard'],
						child: {
							viewAnalysis: {
								key: 'viewAnalysis',
								path: ['devicesview', 'dashboard', 'sensor', 'viewAnalysis'],
							},
							deactivateAlarming: {
								key: 'deactivateAlarming',
								path: ['devicesview', 'dashboard', 'sensor', 'deactivateAlarming'],
							},
							editSensor: {
								key: 'editSensor',
								path: ['devicesview', 'dashboard', 'sensor', 'editSensor'],
							},
							startNewRun: {
								key: 'startNewRun',
								path: ['devicesview', 'dashboard', 'sensor', 'startNewRun'],
							},
							replaceSensor: {
								key: 'replaceSensor',
								path: ['devicesview', 'dashboard', 'sensor', 'replaceSensor'],
							},
							manageLicence: {
								key: 'manageLicence',
								path: ['devicesview', 'dashboard', 'sensor', 'manageLicence'],
							},
							downloadFactoryCalibrationCertificate: {
								key: 'downloadFactoryCalibrationCertificate',
								path: ['devicesview', 'dashboard', 'sensor', 'downloadFactoryCalibrationCertificate'],
							},
							deleteSensor: {
								key: 'deleteSensor',
								path: ['devicesview', 'dashboard', 'sensor', 'deleteSensor'],
							},
						},
					},
					sensorAnalysis: {
						key: 'sensorAnalysis',
						path: ['devicesview', 'dashboard'],
						child: {
							createReport: {
								key: 'createReport',
								path: ['devicesview', 'dashboard', 'sensorAnalysis', 'createReport'],
							},
							createExcelReport: {
								key: 'createExcelReport',
								path: ['devicesview', 'dashboard', 'sensorAnalysis', 'createExcelReport'],
							},
							selectPreviousSensorRuns: {
								key: 'selectPreviousSensorRuns',
								path: ['devicesview', 'dashboard', 'sensorAnalysis', 'selectPreviousSensorRuns'],
							},
						},
					},
				},
			},
			datagrid: {
				key: 'datagrid',
				path: ['devicesview', 'datagrid'],
				child: {
					addSensor: {
						key: 'addSensor',
						path: ['devicesview', 'datagrid', 'addSensor'],
					},
				},
			},
		},
	},

	deviations: {
		child: {
			viewActiveDeviations: {key: 'viewActiveDeviations', path: ['deviations', 'viewActiveDeviations']},
			viewInactiveDeviations: {key: 'viewInactiveDeviations', path: ['deviations', 'viewInactiveDeviations']},
			multiAcknowledge: {key: 'multiAcknowledge', path: ['deviations', 'multiAcknowledge']},
			deviationsDetail: {
				key: 'deviationsDetail',
				path: ['deviations', 'deviationsDetail'],
				child: {
					createReport: {
						key: 'createReport',
						path: ['deviations', 'deviationsDetail', 'createReport'],
					},
					deviationAcknowledge: {
						key: 'deviationAcknowledge',
						path: ['deviations', 'deviationsDetail', 'deviationAcknowledge'],
					},
				},
			},
		},
	},
	auditTrail: {
		child: {
			createReport: {
				key: 'auditTrail',
				path: ['auditTrail', 'createReport'],
			},
		},
	},
	calibrations: {
		key: 'calibrations',
	},
	settings: {
		child: {
			sensorGroups: {
				key: 'sensorGroups',
				path: ['settings', 'sensorGroups'],
				child: {
					sensorGroup: {
						key: 'sensorGroup',
						path: ['settings', 'sensorGroups', 'sensorGroup'],
						child: {
							addGroup: {
								key: 'addGroup',
								path: ['settings', 'sensorGroups', 'sensorGroup', 'addGroup'],
							},
							editGroup: {
								key: 'editGroup',
								path: ['settings', 'sensorGroups', 'sensorGroup', 'editGroup'],
							},
							deleteGroup: {
								key: 'deleteGroup',
								path: ['settings', 'sensorGroups', 'sensorGroup', 'deleteGroup'],
							},
						},
					},
				},
			},
			limitProfiles: {
				key: 'limitProfiles',
				path: ['settings', 'limitProfiles'],
				child: {
					limitProfile: {
						key: 'limitProfile',
						path: ['settings', 'limitProfiles', 'limitProfile'],
						child: {
							addLimitProfile: {
								key: 'addLimitProfile',
								path: ['settings', 'limitProfiles', 'limitProfile', 'addLimitProfile'],
							},
							editLimitProfile: {
								key: 'editLimitProfile',
								path: ['settings', 'limitProfiles', 'limitProfile', 'editLimitProfile'],
							},
							deleteLimitProfile: {
								key: 'deleteLimitProfile',
								path: ['settings', 'limitProfiles', 'limitProfile', 'deleteLimitProfile'],
							},
						},
					},
				},
			},
			issueProfiles: {
				key: 'issueProfiles',
				path: ['settings', 'issueProfiles'],
				child: {
					limitProfile: {
						key: 'issueProfile',
						path: ['settings', 'issueProfiles', 'issueProfile'],
						child: {
							addIssueProfile: {
								key: 'addIssueProfile',
								path: ['settings', 'issueProfiles', 'issueProfile', 'addIssueProfile'],
							},
							editIssueProfile: {
								key: 'editIssueProfile',
								path: ['settings', 'issueProfiles', 'issueProfile', 'editIssueProfile'],
							},
							deleteIssueProfile: {
								key: 'deleteIssueProfile',
								path: ['settings', 'issueProfiles', 'issueProfile', 'deleteIssueProfile'],
							},
						},
					},
				},
			},
			licenses: {
				key: 'licenses',
				path: ['settings', 'licenses'],
				child: {
					extendLicenses: {key: 'extendLicenses', path: ['settings', 'licenses', 'extendLicenses']},
					extendRetiredLicenses: {
						key: 'extendRetiredLicenses',
						path: ['settings', 'licenses', 'extendRetiredLicenses'],
					},
					retireLicenses: {key: 'retireLicenses', path: ['settings', 'licenses', 'retireLicenses']},
				},
			},
			billing: {
				key: 'billing',
				path: ['settings', 'billing'],
				child: {
					creditCard: {
						key: 'creditCard',
						path: ['settings', 'billing', 'creditCard'],
						child: {
							addCreditCard: {
								key: 'addCreditCard',
								path: ['settings', 'billing', 'creditCard', 'addCreditCard'],
							},
							deleteCreditCard: {
								key: 'deleteCreditCard',
								path: ['settings', 'billing', 'creditCard', 'deleteCreditCard'],
							},
							changeDefaultCreditCard: {
								key: 'changeDefaultCreditCard',
								path: ['settings', 'billing', 'creditCard', 'changeDefaultCreditCard'],
							},
						},
					},
					prepaidCard: {
						key: 'prepaidCard',
						path: ['settings', 'billing', 'prepaidCard'],
						child: {
							rechargePrepaid: {
								key: 'rechargePrepaid',
								path: ['settings', 'billing', 'prepaidCard', 'rechargePrepaid'],
							},
						},
					},
					payments: {
						key: 'payments',
						path: ['settings', 'billing', 'payments'],
						child: {
							viewPayment: {
								key: 'viewPayment',
								path: ['settings', 'billing', 'payments', 'viewPayment'],
							},
						},
					},
				},
			},
			organization: {
				key: 'organization',
				path: ['settings', 'organization'],
				child: {
					editOrganization: {
						key: 'editOrganization',
						path: ['settings', 'organization', 'editOrganization'],
					},
				},
			},
			userProfile: {
				key: 'userProfile',
				path: ['settings', 'userProfile'],
				child: {
					editUserProfile: {
						key: '',
						path: ['settings', 'userProfile', 'editUserProfile'],
						child: {
							editUserData: {
								key: 'editUserData',
								path: ['settings', 'userProfile', 'editUserProfile', 'editUserData'],
							},
							editUserSettings: {
								key: 'editUserSettings',
								path: ['settings', 'userProfile', 'editUserProfile', 'editUserSettings'],
							},
							changeUserPassword: {
								key: 'changeUserPassword',
								path: ['settings', 'userProfile', 'editUserProfile', 'changeUserPassword'],
							},
						},
					},
				},
			},
			userManagement: {
				key: 'userManagement',
				path: ['settings', 'userManagement'],
				child: {
					createUser: {
						key: 'createUser',
						path: ['settings', 'userManagement', 'createUser'],
					},
					deleteUser: {
						key: 'deleteUser',
						path: ['settings', 'userManagement', 'deleteUser'],
					},
					disableUser: {
						key: 'disableUser',
						path: ['settings', 'userManagement', 'disableUser'],
					},
					editUserAccess: {
						key: 'editUserAccess',
						path: ['settings', 'userManagement', 'editUserAccess'],
					},
					sendActivationMail: {
						key: 'sendActivationMail',
						path: ['settings', 'userManagement', 'sendActivationMail'],
					},
				},
			},
			predictive: {
				key: 'predictive',
				path: ['settings', 'predictive'],
				child: {
					addSmartCaeCustomerId: {
						key: 'addSmartCaeCustomerId',
						path: ['settings', 'predictive', 'addSmartCaeCustomerId'],
					},
				},
			},
		},
	},
};

export default {
	devicesview: AccessPermissions.devicesview,
	deviations: AccessPermissions.deviations,
	auditTrail: AccessPermissions.auditTrail,
	calibrations: AccessPermissions.calibrations,
	settings: AccessPermissions.settings,
};
