import React, {useContext, useEffect, useState} from 'react';
import {FormNavigation} from '../../Elements';
import {Alert, Col, Form, Row, Select} from 'antd';
import {useTranslation} from 'react-i18next';
import {colProps, formProps} from '../../defaults';
import {AlarmLimit} from '../../../../common/types';
import {AlarmLimitSelect} from '../../FormItems';
import {PredictiveTrans} from './Trans';
import {PredictionService} from '../../../../common/services';
import {OutUnit} from '../../../../common/constants/OutUnit';
import {DateTimeUtils} from '../../../Infrastructure/DateTime/DateTimeUtils';
import {AuthContext} from '../../../../common/context';
import {IShippingContainer as Container, IShippingLane as Lane} from '@elproag/predict';

export type PredictiveFormState = {
	alarmLimit: AlarmLimit;
	alarmLimitOutUnit: number;
	container: Container;
	enabled: boolean;
	lane: Lane;
};

export function PredictiveForm(props: {
	initialState: PredictiveFormState;
	loading: boolean;
	onBack: () => void;
	onNext: () => void;
	outUnit: OutUnit;
	saveStateCallback: (predictiveFormState: PredictiveFormState) => void;
}): React.JSX.Element {
	const [t] = useTranslation();
	const authContext = useContext(AuthContext);
	const orgId = authContext?.Organization?.Uuid;

	const [enabled, setEnabled] = useState(props.initialState?.enabled ?? false);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const [containers, setContainers] = useState<Container[]>([]);
	const [containerId, setContainerId] = useState<string>(props.initialState?.container?.id);
	const [lanes, setLanes] = useState<Lane[]>([]);
	const [laneId, setLaneId] = useState<string>(props.initialState?.lane?.id);
	const [alarmLimit, setAlarmLimit] = useState<AlarmLimit>(props.initialState?.alarmLimit);

	let containerOptions = containerResponseToOptions(containers);
	let laneOptions = laneResponseToOptions(lanes);

	function createErrorMessage(prev: string | null, message: string): string {
		let newMessage = prev ? `${prev}\n` : '';
		newMessage += message;
		return newMessage;
	}

	useEffect(() => {
		if (enabled) {
			Promise.all([PredictionService.GetContainers(orgId), PredictionService.GetLanes(orgId)])
				.then(([containers, lanes]) => {
					if (containers.length === 0)
						setErrorMessage(prev =>
							createErrorMessage(
								prev,
								'No boxes returned. Check if generic boxes are included in elproPREDICT settings, or contact SmartCAE.'
							)
						);
					else setContainers(containers);
					if (lanes.length === 0)
						setErrorMessage(prev =>
							createErrorMessage(
								prev,
								'No lanes returned. Check if generic lanes are included in elproPREDICT settings, or contact SmartCAE.'
							)
						);
					else setLanes(lanes);
				})
				.catch(error => {
					console.error(error);
					setErrorMessage(t('error.serviceError', {service: 'Predictive'}));
					setEnabled(false);
				});
		}
	}, [enabled]);

	const onSubmitSuccess = (_values: any) => {
		const predictiveFormState: PredictiveFormState = {
			alarmLimit: alarmLimit,
			alarmLimitOutUnit: props.outUnit,
			container: containers.find(c => c.id === containerId),
			enabled: enabled,
			lane: lanes.find(l => l.id === laneId),
		};

		props.saveStateCallback(predictiveFormState);
		props.onNext();
	};

	const onSubmitError = (_: unknown) => {
		if (!enabled) {
			props.onNext();
		}
	};

	function containerResponseToOptions(response: Container[]): {value: string; label: string}[] {
		try {
			return response.map(item => ({value: item.id, label: item.name}));
		} catch {
			return [];
		}
	}

	function laneResponseToOptions(response: Lane[]): {value: string; label: string}[] {
		try {
			return response.map(item => ({
				value: item.id,
				label: `${item.name} (${DateTimeUtils.formatSecondsAsDuration(item.durationInSeconds)})`,
			}));
		} catch {
			return [];
		}
	}

	return (
		<Form onFinish={onSubmitSuccess} onFinishFailed={onSubmitError} {...formProps}>
			<FormNavigation loading={false}></FormNavigation>
			<Row>
				<Col {...colProps}>
					<Alert
						description={
							<ul>
								<li>The predicted timeframe will be exactly as long as the shipment duration specified by the lane</li>
								<li>Only the upper and lower limit from the alarm limit are taken into account</li>
								<li>The delay of the alarm limit is discarded</li>
								<li>The lane will begin with the first measurement</li>
								<li>Allow new run by START button - is not supported</li>
							</ul>
						}
						type="warning"
					/>
				</Col>
			</Row>
			{errorMessage && <Alert message={t('error.title').toString()} description={errorMessage} type="error" showIcon />}
			<Form.Item label={t(PredictiveTrans.container).toString()} name="Box" rules={[{required: true}]} initialValue={containerId}>
				<Select options={containerOptions} disabled={!enabled} onChange={setContainerId} />
			</Form.Item>

			<Form.Item label={t(PredictiveTrans.lane).toString()} name="Lane" rules={[{required: true}]} initialValue={laneId}>
				<Select options={laneOptions} disabled={!enabled} onChange={setLaneId} />
			</Form.Item>

			<AlarmLimitSelect
				disabled={!enabled}
				hideDelay={true}
				initialAlarmLimit={alarmLimit}
				label={t(PredictiveTrans.alarmLimit)}
				onSelectCallback={setAlarmLimit}
				outUnit={props.outUnit}
				tooltip={t(PredictiveTrans.limitAlarmTooltip)}
				hideMultiZone={true}
			/>
			<FormNavigation loading={false} onBack={props.onBack}></FormNavigation>
		</Form>
	);
}
