import React, {createContext, useContext, useEffect, useState} from 'react';
import {Organization} from '../types';
import {GetOrganization} from '../services/WebserverServicePro/OrganizationService';
import {AccessContext} from '../../components/Infrastructure/Authorization/Context/AccessContext';
import pubsub from 'pubsub-js';
import {UI_EVENT} from '../../components/Shared/Constants/Events';
import {AuthUtils} from '../util';

/**
 * Idea: Add user and maybe the auth token to this at a later point in time
 */
export interface AuthContextType {
	Organization?: Organization;
}

/**
 * Provide the authentication context to the React components
 *
 * Currently, this depends on the AccessContext, which is the wrong way, it should be refactored, to provide the token
 * to the access context instead. This is a bigger rework, as the UserIdle component should probably be integrated here,
 * as well as some other functions that are currently part of the Authentication.js class.
 */
export const AuthContext = createContext<AuthContextType>(null);

export const AuthContextProvider = ({children}) => {
	const {accessToken} = useContext(AccessContext);
	const [value, setValue] = useState<AuthContextType>(null);
	const [stale, setStale] = useState<boolean>(true);

	pubsub.subscribe(UI_EVENT.ORGANISATION_CHANGED, (_: string, _2: Partial<Organization>) => setStale(true));

	useEffect(() => {
		if (!accessToken && !stale) return;
		const cloudToken = AuthUtils.GetElproCloudToken();
		if (!cloudToken) return;

		GetOrganization(cloudToken.OrgId)
			.then(o => {
				setValue(prevState => ({
					...prevState,
					Organization: o,
				}));
				setStale(false);
			})
			.catch(() => {});
	}, [accessToken, stale]);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
